/* Component Dependencies */
var heroImageITOTemplate = require('templates/heroImageITO.hbs')
var AriesComponent = require('libs/aries-component');
var Popup = require('libs/popup');
var Cookies = require('libs/cookies');

AriesComponent.create({
  type: 'heroImageITO',
  template: {
    'heroImageITO': heroImageITOTemplate
  },
		bindEvents: function () {
		var _self = this;
		var currentSessionId = _self.getSessionToken(), 
          lastSessionId = Cookies.readCookie('ctLastSessionID');
				_self.resizeHandler();
				$(window).on("resize",function() {
					_self.resizeHandler();
			});
			if(currentSessionId !== lastSessionId){
			_self.openTranslationMsgPopup();
			}
			
		},
		resizeHandler:function(){
  		var _self = this,
  		headlineOffset = $('.t-ito-text').offset(),
  		headlineTextOffset = $('.t-ito-text .t-ito-copy').offset(),
		connectorWidth;
		if(headlineOffset) {
			$('.m-connector-primary').css('margin-left', '-'+headlineOffset.left+'px');
		}
		if(headlineTextOffset) {
  			connectorWidth = headlineTextOffset.left-46;
			$('.m-connector-primary .line').css('width', connectorWidth+'px');
		}
  	},
	  openTranslationMsgPopup: function () {
		//translation message popup
		console.log("TranslationMsgPopup");
		if(msgResources.exlnLangFlag ==='true'){
			 var translationPop = new Popup({
					url: msgResources.translationMessageOverlayURL,
					reverseCloseIcon: true,
					open: true,
					sourceBlock: '.modal-content'
				  });
				  translationPop.register();
			}
		return true;
	  }
});

